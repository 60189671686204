import React from "react"
import Layout from "../components/js/layout"
import SEO from "../components/js/seo"

import lecturesData from "./../data/lectures.yaml"

class RedirPage extends React.Component {

  render () {
    return (
      <Layout page="python-redir">
        <SEO title="얄코의 제대로 파는 파이썬"/>
        <section className="redir" style={{textAlign: "center", color: "white"}}>
          강의로 이동중...
        </section>
      </Layout>
    )
  }

  componentDidMount () {

    const python = lecturesData.find(lec => lec.code === "python")

    const discount = python.discount
    const today = new Date()
    const dateFrom = new Date(discount.split('~')[0])
    const dateTo = new Date(discount.split('~')[1] + ' 23:59:59')

    const link = (today > dateFrom && today < dateTo) ? python.coupon : python.ordinary 
    window.location.replace(link)

  }
}

export default RedirPage